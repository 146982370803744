import { Text, Button } from "@fluentui/react-components";
import { DocumentOnePageAddRegular, PollHorizontalRegular, SendRegular } from "@fluentui/react-icons";
import { EmptyFlexRow, Flex, FlexItem } from "../blocks/FlexBoxes";
import { EnumPollWorkflow, PollContext } from "sub/context/PollContext";
import { useContext, useEffect } from "react";
import { ListPollsTable } from "./ListPollsTable";
import { TabContext, TabTableMode } from "sub/context/TabContext";
import { ListPollsSearch } from "./ListPollsSearch";
import { backendTrackEvent } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";

import { ListPollsModeSelector } from "./ListPollsMode";


export function PollReuseTable() {

    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);
    const pollContext = useContext(PollContext);

    useEffect(() => {
        // Track events
        backendTrackEvent(appContext.appAuthData.lang, "Chat", "ListPolls", "", appContext);
    }, []);

    return (
        <>
            {/* Title Content */}
            <Flex alignItems="center" gap="medium" wrap wrapAlignContent="flex-start">
                <ListPollsModeSelector />
                <FlexItem push>
                    <ListPollsSearch />
                </FlexItem>
            </Flex>
            <EmptyFlexRow size="1.0" />

            {/* Main Content */}
            {tabContext.tableMode === TabTableMode.modePolls && (
                <Text wrap>Choose an existing draft to start a new poll or continue with an existing poll (<SendRegular />).</Text>
            )}
            {tabContext.tableMode === TabTableMode.modeTemplates && (
                <Text wrap>Create a new poll based on one of your templates (<DocumentOnePageAddRegular />).</Text>
            )}


            <EmptyFlexRow size="0.5" />
            <Flex column fillHeight gap="small" style={{ overflow: 'auto', paddingRight: "0.5em" }}>
                <ListPollsTable />
            </Flex>

            {/* Button Footer */}
            <EmptyFlexRow size="0.5" />

            <FlexItem column push>
                <Flex justifyContent="flex-end" gap="medium">
                    <Button onClick={() => { pollContext.setPollWorkflow(EnumPollWorkflow.task_selector) }}>Back</Button>
                </Flex>
            </FlexItem>
        </>
    );
}