import { Text, Button, Title3, tokens, Link, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { EmptyFlexRow, Flex, FlexItem } from "../blocks/FlexBoxes";
import { EnumPollWorkflow, PollContext, } from "sub/context/PollContext";
import { useContext, useRef, useState } from "react";
import { dialog as teamsDialog } from "@microsoft/teams-js";
import { hyDebug } from "sub/library/hyDebug";
import { BotTaskSubmitData } from "sub/bot-client-shared/BotTaskSubmitData";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { app_name, app_version, getSSOToken, refreshTicker } from "sub/library/epCommon";
import { GetCurrentOffset, backendCreatePollClient, backendEditPollClient, backendGetPollOrResultClient } from "sub/library/epBackend";
import { cleanCardFromActions, createCardFromPollData } from "sub/bot-client-shared/adaptiveCards/botCardBuilder";
import { AdaptiveCardReact } from "sub/blocks/AdaptiveCardReact";
import { pollExists } from "sub/bot-client-shared/PollDataClass";
import { ProTag, TextFreePlanLimit, TextFreePlanRestrictionChat, TextFreePlanRestrictionTab, TextFreePlan, TextPreviewWorkflowInfo, PopupFreePlanLimit, WarningEditMode } from "./CommonMisc";


const react_app_id = process.env.REACT_APP_CLIENT_ID;

export function PollPreview() {

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    // IsLoading is only set to true when a reuse poll is previewed.
    // New polls already contain the complete data. Loading is not necessary.
    const [isWorking, setIsWorking] = useState(false);
    const [isSubmitError, setIsSubmitError] = useState(false);


    const [showBuyPopup, setShowBuyPopup] = useState(false);
    const refButtonPost = useRef<HTMLButtonElement>(null);

    // Initialize the card with info from polldata class.
    // For new polls: This already contains the whole poll.
    // For reuse polls: This contains only the header information from the poll. Details from backend must be fetched.
    const cardPayload = cleanCardFromActions(createCardFromPollData(null, pollContext.pollData, false, appContext.appAuthData.locale), true);

    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // Define steering constants
    // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    const editMode = (pollContext.pollWorkflow === EnumPollWorkflow.editPoll_preview || pollContext.pollWorkflow === EnumPollWorkflow.editPoll_preview_close);

    // Bool to check if the limit is reached.
    const isLimitReached = (() => {
        if (appContext.appAuthData.hasActiveLicense) { return false; }
        if (pollContext.pollData.is_template) { return false; }

        if (pollContext.pollWorkflow === EnumPollWorkflow.newPoll_preview && appContext.appAuthData.pollLimit) {
            return true;
        } else if (pollContext.pollWorkflow === EnumPollWorkflow.reusePoll_preview && pollContext.pollData.poll_results_restricted) {
            return true;
        }

        // Default value:
        return false;
    })();

    // Bool to check if the save button shall be enabled.
    const enableSaveForLaterButton = (appContext.environment.current === EnumAppEnvironment.message_chat && pollContext.pollWorkflow === EnumPollWorkflow.newPoll_preview);

    // Define primaryButtonText
    const primaryButtonText = (() => {
        if (appContext.environment.current === EnumAppEnvironment.message_chat) {
            return "Post";
        } else {
            // Tab mode
            if (editMode) {
                return "Save";
            } else {
                return "Create";
            }
        }
    })();

    return (
        <>
            {/* Title Content */}
            {appContext.environment.current === EnumAppEnvironment.message_chat && (
                <Flex gap="small" alignItems="center">
                    <Title3>Preview</Title3>

                    <FlexItem push>
                        {!appContext.appAuthData.hasActiveLicense && (<TextFreePlan />)}
                        {appContext.appAuthData.hasActiveLicense && (<ProTag />)}
                    </FlexItem>
                </Flex>
            )}

            {!editMode && !appContext.appAuthData.hasActiveLicense && (
                <>
                    {appContext.environment.current === EnumAppEnvironment.message_chat && (
                        <EmptyFlexRow size="0.25" />
                    )}

                    {!pollContext.pollData.is_template && !isLimitReached && (
                        <TextFreePlanLimit template={pollContext.pollData.is_template} />
                    )}

                    {!pollContext.pollData.is_template && isLimitReached && (
                        <>
                            {appContext.environment.current === EnumAppEnvironment.message_chat && (<TextFreePlanRestrictionChat />)}
                            {appContext.environment.current === EnumAppEnvironment.personal_tab && (<TextFreePlanRestrictionTab />)}
                        </>
                    )}

                    {pollContext.pollData.is_template && (
                        <TextFreePlanLimit template={pollContext.pollData.is_template} />
                    )}


                </>
            )}

            {/* Main Content */}
            <Flex column fillFlex gap="small" padding="medium" alignItems="center" style={{ overflow: 'auto' }}>
                <div style={{ maxWidth: '65%', height: "80%" }}>
                    <AdaptiveCardReact cardPayload={cardPayload} />
                </div>
            </Flex>

            {showBuyPopup && appContext.environment.current === EnumAppEnvironment.message_chat && (
                <PopupFreePlanLimit appContext={appContext} situation="post" refElement={refButtonPost} fShowPopup={setShowBuyPopup} />
            )}

            {isSubmitError && (
                <>
                    <MessageBar intent="error">
                        <MessageBarBody>That did not work. Please try again or contact support.</MessageBarBody>
                    </MessageBar>
                    <EmptyFlexRow size="0.5" />
                </>
            )}


            {/* Button Footer */}
            <FlexItem column push>
                <Flex justifyContent="flex-start" alignItems="center" gap="medium" wrap>

                    <TextPreviewWorkflowInfo appEnvironment={appContext.environment.current} poll_is_template={pollContext.pollData.is_template} workflow={pollContext.pollWorkflow} />

                    <FlexItem push>
                    </FlexItem>

                    {enableSaveForLaterButton && (
                        <Text><Link disabled={isWorking} onClick={async () => { await _save(); }} style={{ fontWeight: (isLimitReached ? "bold" : "normal") }}>Save as draft</Link></Text>
                    )}

                    <Button disabled={isWorking} onClick={() => { _back(); }}>Back</Button>

                    <Button ref={refButtonPost} disabled={isWorking} onClick={async () => { await _submit(); }} appearance="primary">{primaryButtonText}</Button>

                </Flex >
            </FlexItem >
        </>
    );

    function _back() {
        let action: EnumPollWorkflow;

        if (pollContext.pollWorkflow === EnumPollWorkflow.newPoll_preview) {
            if (pollContext.pollData.is_template) {
                action = EnumPollWorkflow.newTemplate_entry;
            } else {
                action = EnumPollWorkflow.newPoll_entry;
            }

        } else if (pollContext.pollWorkflow === EnumPollWorkflow.editPoll_preview) {
            action = EnumPollWorkflow.editPoll_entry;

        } else {
            action = EnumPollWorkflow.reusePoll_table;
        }

        pollContext.setPollWorkflow(action);
    }

    async function _submit() {
        try {
            if (appContext.environment.current === EnumAppEnvironment.message_chat) {

                if (isLimitReached) {
                    setShowBuyPopup(true);
                } else {
                    setIsWorking(true);
                    setIsSubmitError(false);

                    const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : "";

                    const botData: BotTaskSubmitData = {
                        userUPN: appContext.appAuthData.userUPN,
                        userID: appContext.appAuthData.userID,
                        userToken: appContext.appAuthData.userToken,
                        userSSO: sso_token,
                        
                        userLang: appContext.appAuthData.lang,
                        userLocale: appContext.appAuthData.locale,

                        app_name: app_name,
                        app_version: app_version,
                        utc_offset: GetCurrentOffset(),

                        task: "create_poll",
                        messageId: "",
                        pollExists: pollExists(pollContext.pollData),
                        pollData: pollContext.pollData
                    }

                    // Submit will automatically close the dialog
                    teamsDialog.url.submit(botData, react_app_id!);
                }


            } else if (appContext.environment.current === EnumAppEnvironment.personal_tab) {
                setIsWorking(true);
                setIsSubmitError(false);

                if (!editMode) {
                    // Create a new poll
                    const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
                    const createPollResult = await backendCreatePollClient(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token,appContext.appAuthData.lang, appContext.appAuthData.displayName, pollContext.pollData, false);

                    // Update poll context for fast gui updates.
                    if (createPollResult.success) {
                        pollContext.updatePollData(createPollResult.pollData);

                        // Close the poll when done.
                        pollContext.setPollWorkflow(EnumPollWorkflow.newPoll_preview_close);
                    } else {
                        setIsSubmitError(true);
                    }

                } else {
                    // Save the edited poll
                    const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
                    const editPollResult = await backendEditPollClient("edit_poll", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken,sso_token, appContext.appAuthData.lang, pollContext.pollData.poll_guid, pollContext.pollData.poll_secret, pollContext.pollData);

                    // Update poll context for fast gui updates.
                    if (editPollResult.success) {
                        pollContext.updatePollData(editPollResult.pollData);
                        // Close the poll when done.
                        pollContext.setPollWorkflow(EnumPollWorkflow.editPoll_preview_close);
                    } else {
                        setIsSubmitError(true);
                    }

                }
            } else {
                hyDebug("PollPreview: Unknown poll purpose");
            }

        } catch (err: any) {
            setIsSubmitError(true);
            hyDebug(err);
        }
    }

    async function _save() {
        try {
            if (appContext.environment.current === EnumAppEnvironment.message_chat) {
                setIsWorking(true);
                setIsSubmitError(false);

                const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
                const response = await backendCreatePollClient(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, appContext.appAuthData.displayName, pollContext.pollData, false);

                if (response.success) {
                    // Wait 1 sec so the user can see the GUI working.
                    await new Promise(r => setTimeout(r, 1000));

                    // Submit will automatically close the dialog
                    teamsDialog.url.submit();
                } else {
                    setIsSubmitError(true);
                }

            } else {
                hyDebug("PollPreview: Unknown poll purpose");
            }

        } catch (err: any) {
            setIsSubmitError(true);
            hyDebug(err);
        }
    }
}




