import { Text, Button, Field, Input, Label, Title3, InputOnChangeData, MessageBar, MessageBarBody } from "@fluentui/react-components";

import { DeleteRegular, AddSquareRegular } from "@fluentui/react-icons";

import { EmptyFlexRow, Flex, FlexItem } from "../blocks/FlexBoxes";
import { EnumPollWorkflow, PollContext } from "sub/context/PollContext";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { PollOptions } from "./PollOptions";
import { EnumPolLView, TypePollAnswer, empty_guid } from "sub/bot-client-shared/PollDataClass";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { backendTrackEvent } from "sub/library/epBackend";
import { ProTag, TextFreePlan, WarningEditMode } from "./CommonMisc";
import { AnswerActionMenu } from "./AnswerActionMenu";

export function PollCreateOrEdit() {

    const [isFormRequiredError, setIsFormRequiredError] = useState(false);
    const [calendarLastDate, setCalendarLastDate] = useState<Date | undefined>(undefined);

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    const editMode = pollContext.pollWorkflow === EnumPollWorkflow.editPoll_entry;

    useEffect(() => {
        // Track events
        const category = appContext.environment.current === EnumAppEnvironment.message_chat ? "Chat" : "Tab";
        backendTrackEvent(appContext.appAuthData.lang, category, (editMode ? "EditPoll" : "CreateNewPoll"), (editMode ? "Questions/Answers" : ""), appContext);
    }, []);




    // Actual component
    return (
        <>
            {/* Title Content (only in chat) */}
            {appContext.environment.current === EnumAppEnvironment.message_chat && (
                <>
                    <Flex gap="small" alignItems="center">

                        <Title3>Create a new poll</Title3>

                        <FlexItem push>
                            {!appContext.appAuthData.hasActiveLicense && (<TextFreePlan />)}
                            {appContext.appAuthData.hasActiveLicense && (<ProTag />)}
                        </FlexItem>

                    </Flex>

                    <EmptyFlexRow size="1.0" />
                </>
            )}



            {/* Main Content */}
            <Flex column fillHeight gap="small" style={{ overflow: 'auto', paddingRight: "0.5em" }}>

                {editMode && (
                    <WarningEditMode is_template={pollContext.pollData.is_template} />
                )}

                <Field required label={<Text weight="semibold">Question</Text>}><Input placeholder="Enter your question" value={pollContext.pollData.question} onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { __setQuestion(data.value); }} contentAfter={<AnswerActionMenu newText={(val: string, append: boolean) => { __setQuestion(val, append); }} anchor="question" calendarLastDate={calendarLastDate} setCalendarLastDate={setCalendarLastDate} />} /></Field>
                <EmptyFlexRow size="1.5" />

                <Label required weight="semibold">Answers</Label>
                {pollContext.pollData.answers.map((answer, index) => (
                    <Flex key={answer.seq} gap="small" alignItems="center">
                        <Label>{`Answer ${index + 1}:`}</Label>
                        <Input placeholder={`Enter your answer ${index + 1}`} style={{ flexGrow: 1 }} value={answer.text} onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { __setAnswerText(answer.seq, data.value, false); }} contentAfter={<AnswerActionMenu newText={(val: string, append: boolean) => { __setAnswerText(answer.seq, val, append); }} anchor="answer" calendarLastDate={calendarLastDate} setCalendarLastDate={setCalendarLastDate} />} />
                        <Button disabled={!editMode && (index === 0 || index === 1)} icon={<DeleteRegular />} onClick={() => __removeAnswer(answer.seq)} />
                    </Flex>
                ))}
                <FlexItem push><Button icon={<AddSquareRegular />} onClick={__addAnswer}>More</Button></FlexItem>

                <EmptyFlexRow size="0.25" />

                <PollOptions editMode={editMode} />

            </Flex>

            {/* Button Footer */}
            <EmptyFlexRow size="0.5" />

            {isFormRequiredError && (
                <>
                    <MessageBar intent="error">
                        <MessageBarBody>Please provide a question and at least two answers.</MessageBarBody>
                    </MessageBar>
                    <EmptyFlexRow size="0.5" />
                </>
            )}

            <FlexItem column push>
                <Flex justifyContent="flex-end" gap="medium">
                    {appContext.environment.current === EnumAppEnvironment.message_chat && (<Button onClick={() => { _chat_back(); }}>Back</Button>)}
                    {appContext.environment.current === EnumAppEnvironment.personal_tab && (<Button onClick={() => { _tab_close(); }}>Close</Button>)}
                    <Button onClick={() => { __moveToPreview(); }} appearance="primary">Preview</Button>
                </Flex>
            </FlexItem>
        </>
    );

    function __addAnswer() {

        // Find the next seq by adding 1 to the current highest seq
        const nextId = Math.max(...pollContext.pollData.answers.map(a => a.seq)) + 1;
        const newAnswer: TypePollAnswer = { seq: nextId, answer_guid: empty_guid, text: "", pre_select: false, answer_votes: 0, voter_names: "" };
        pollContext.updatePollData({ answers: [...pollContext.pollData.answers, newAnswer] });

    };

    function __removeAnswer(seq: number) {

        pollContext.updatePollData({ answers: pollContext.pollData.answers.filter(answer => answer.seq !== seq) });

    };

    function __setQuestion(val: string, append: boolean = false) {
        const text = (append) ? pollContext.pollData.question + val : val;
        pollContext.updatePollData({ question: text });
    }

    function __setAnswerText(seq: number, val: string, append: boolean) {

        pollContext.updatePollData({
            answers: pollContext.pollData.answers.map(answer => {
                if (answer.seq === seq) {
                    const text = (append) ? answer.text + val : val;
                    return { ...answer, text };
                }
                return answer;
            })
        });
    };

    function _tab_close() {
        pollContext.setPollWorkflow(EnumPollWorkflow.close);
    }

    function _chat_back() {
        // Only in Chat environment

        if (!pollContext.pollData.source_poll) {
            pollContext.setPollWorkflow(EnumPollWorkflow.task_selector)
        } else {
            pollContext.setPollWorkflow(EnumPollWorkflow.reusePoll_table)
        }
    }

    function __moveToPreview() {
        // +++++++++++++ Perform checks

        // Check if question is filled:
        if (!pollContext.pollData.question) { setIsFormRequiredError(true); return; }

        // Check number of answers only when option for adding new answers is disabled.
        if (!pollContext.pollData.option_voters_add_answers) {
            // Check if at least one answer was given:
            if (!pollContext.pollData.answers.some(answer => answer.text.trim() !== '')) { setIsFormRequiredError(true); return; }

            // Check if at least two answers are given:
            if (pollContext.pollData.answers.length < 2) { setIsFormRequiredError(true); return; }
        }

        // +++++++++++++ Everything is fine. Now clean up!

        // Set poll view
        pollContext.pollData.poll_view = EnumPolLView.poll;

        // Set poll template data
        if (!editMode) {
            // Only for new polls / templates
            pollContext.pollData.is_template = (pollContext.pollWorkflow === EnumPollWorkflow.newTemplate_entry);
        }

        // Remove empty answers (filter) and renumber ids (map):
        pollContext.updatePollData({
            answers: pollContext.pollData.answers.filter(answer => answer.text.trim() !== '').map((answer, index) => ({
                ...answer,
                seq: index + 1, // Start seq from 1
            }))
        });

        // Reset max limit number if votes are unlimited
        if (!pollContext.pollData.option_votes_have_limit) {
            pollContext.updatePollData({
                option_votes_max_limit: 1
            });
        }

        // Move to Preview and hide error message.
        if (editMode) {
            pollContext.setPollWorkflow(EnumPollWorkflow.editPoll_preview);
        } else {
            pollContext.setPollWorkflow(EnumPollWorkflow.newPoll_preview);
        }
        setIsFormRequiredError(false);
    }
}