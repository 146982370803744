import React, { useRef } from "react";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { ListPollResults } from "sub/library/epBackendTypes";

export enum TabDialogType {
    None,
    GetStarted, // Only used in Personal tab.

    // Polls
    CreateDraft, // Only used in Personal tab.
    DuplicatePoll,
    DeletePoll, // Only used in Personal tab.
    ViewOrPostPoll,

    // Templates
    CreateTemplate, // Only used in Personal tab.
    DuplicateTemplate,
    DeleteTemplate, // Only used in Personal tab.
    ViewTemplate,

    // Poll + Templates
    EditPoll, // Only used in Personal tab.

    // Other tab workflows
    EditVote, // Only used in Personal tab.
    ManageLicense // Only used in Personal tab.
};

export enum TabTableMode {
    modePolls = "modePolls",
    modeTemplates = "modeTemplates"
}

export class TabContextClass {
    public tabDialog: TabDialogType = TabDialogType.None;
    public setTabDialog: (param: TabDialogType) => void = () => undefined;

    // Data from backend
    public listPollResults!: React.MutableRefObject<ListPollResults>;
    public cacheListPolls!: React.MutableRefObject<ListPollResults | undefined>;
    public cacheListTemplates!: React.MutableRefObject<ListPollResults | undefined>;

    // Table elements
    public tableMode: TabTableMode = TabTableMode.modePolls;
    public setTableMode: (param: TabTableMode | ((param: TabTableMode) => TabTableMode)) => void = () => undefined;
    public tableSearch!: React.MutableRefObject<string>;
    public activePollElement!: React.MutableRefObject<PollDataClass>;


    // Refresh ticker for updating the data (from backend)
    public refreshTickerData: number = 1;
    public setRefreshTickerData: (param: number | ((param: number) => number)) => void = () => undefined;

    // Refresh ticker for updating the GUI
    public refreshTickerGUI: number = 1;
    public setRefreshTickerGUI: (param: number | ((param: number) => number)) => void = () => undefined;
}

export const TabContext = React.createContext<TabContextClass>(new TabContextClass());