import { Button, Popover, PopoverSurface } from "@fluentui/react-components";
import { useContext, useRef, useState } from "react";
import { Flex } from "sub/blocks/FlexBoxes";
import { AppContext } from "sub/context/AppContext";

import { Calendar } from "@fluentui/react-calendar-compat";
import { CalendarDateRegular, EmojiRegular } from "@fluentui/react-icons";

import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { isMobile } from "react-device-detect";


// Action Menu for each answer
export const AnswerActionMenu = (props: { newText: (val: string, append: boolean) => void, anchor: "answer" | "question", calendarLastDate : Date | undefined, setCalendarLastDate: React.Dispatch<React.SetStateAction<Date | undefined>> }) => {

    const appContext = useContext(AppContext);

    const buttonEmojiRef = useRef<HTMLButtonElement | null>(null);
    const buttonCalendarRef = useRef<HTMLButtonElement | null>(null);

    const [popoverEmojiOpen, setPopoverEmojiOpen] = useState(false);
    const [popoverDateOpen, setPopoverDateOpen] = useState(false);

    const emojiTheme = (appContext.themeString === "light" || appContext.themeString === "default") ? "light" : "dark";

    const dateOptions: Intl.DateTimeFormatOptions = {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    return (
        <>
            <Flex gap="smallest" padding="none" alignItems="center">
                {(!isMobile) && (
                    <Button ref={buttonEmojiRef} icon={<EmojiRegular />} appearance="transparent" style={{ padding: "0px", margin: "0px", minWidth: "fit-content" }} onClick={() => {
                        setPopoverEmojiOpen(true);
                        setPopoverDateOpen(false);
                    }} title="Add emoji" />
                )}

                {(props.anchor === "answer") && (
                    <Button ref={buttonCalendarRef} icon={<CalendarDateRegular />} appearance="transparent" style={{ padding: "0px", margin: "0px", minWidth: "fit-content" }} onClick={() => {
                        setPopoverEmojiOpen(false);
                        setPopoverDateOpen(true);
                    }} title="Select date" />
                )}

            </Flex>

            <Popover
                open={popoverEmojiOpen}
                onOpenChange={(e, data) => setPopoverEmojiOpen(data.open)}
                // Position the popover relative to the Input element
                positioning={{
                    target: buttonEmojiRef.current, // Attach to the Input element
                    position: 'after', // Position below the Input
                    // align: 'top', // Align to the start (left)
                }}
                size="small">

                <PopoverSurface>
                    <Picker data={data} noCountryFlags previewPosition="none" maxFrequentRows={1} theme={emojiTheme}
                        onEmojiSelect={(item: any) => { props.newText(item?.native ?? "", true); setPopoverEmojiOpen(false); }}
                    />
                </PopoverSurface>
            </Popover>

            <Popover
                open={popoverDateOpen}
                onOpenChange={(e, data) => setPopoverDateOpen(data.open)}
                // Position the popover relative to the Input element
                positioning={{
                    target: buttonCalendarRef.current, // Attach to the Input element
                    position: 'after', // Position below the Input
                    // align: 'top', // Align to the start (left)
                }}
                size="small">

                <PopoverSurface>
                    <Calendar
                        showMonthPickerAsOverlay
                        highlightSelectedMonth
                        showGoToToday
                        firstDayOfWeek={appContext.appAuthData.firstDayOfWeek}
                        value={props.calendarLastDate}
                        onSelectDate={(date: Date) => {
                            props.newText(date.toLocaleDateString(appContext.appAuthData.locale, dateOptions), false);
                            props.setCalendarLastDate(date);
                            setPopoverDateOpen(false);
                        }}
                    />
                </PopoverSurface>
            </Popover>
        </>
    );
}