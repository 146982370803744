import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { Button, Text, DataGrid, DataGridHeader, DataGridRow, DataGridBody, DataGridCell, DataGridHeaderCell, TableColumnDefinition, createTableColumn, TableColumnSizingOptions, Input, useId, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, tokens, InfoLabel } from "@fluentui/react-components";
import { ShieldPersonRegular, BookmarkRegular, DocumentLockRegular, CopyRegular, WarningFilled, SendRegular, DeleteRegular, EditRegular, PersonEditRegular, VoteRegular, LockOpenRegular, LockClosedRegular, LockClosedFilled, EyeRegular, MoreHorizontalFilled, DocumentOnePageAddRegular, TextBulletListAddRegular } from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { AppContext, EnumAppEnvironment } from "sub/context/AppContext";
import { TabContext, TabDialogType, TabTableMode } from "sub/context/TabContext";
import { backendEditPollClient, backendListPolls } from "sub/library/epBackend";
import { getSSOToken, hyDate, link_teams_app_template, refreshTicker, transformDateToDayMonthYear } from "sub/library/epCommon";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";
import Segment from "sub/blocks/Segment";
import { ListPollResults } from "sub/library/epBackendTypes";
import { isMobile } from "react-device-detect";
import { DraftTag, WarningFreePlanRestriction, WarningRestrictionUnlocked } from "./CommonMisc";
import { app as microsoftTeams } from "@microsoft/teams-js";
import { hyDebugLocal } from "sub/library/hyDebug";


export function ListPollsTable() {

    // Get relevant context information
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    // Define table columns and redering functions for the ListPolls table (data grid).
    const tableColumns: TableColumnDefinition<PollDataClass>[] = [
        createTableColumn<PollDataClass>({
            columnId: "index",
            compare: (a, b) => (a.seq - b.seq),
            renderHeaderCell: () => (<b>#</b>),
            renderCell: (item) => item.seq,
        }),
        ...(isMobile ? [] : [
            createTableColumn<PollDataClass>({
                columnId: "created_on",
                compare: (a, b) => a.created_on.localeCompare(b.created_on),
                renderHeaderCell: () => (<b>Created</b>),
                renderCell: (item) => transformDateToDayMonthYear(appContext.appAuthData.locale, hyDate(item.created_on)),
            })
        ]),
        ...((isMobile || tabContext.tableMode === TabTableMode.modeTemplates) ? [] : [
            createTableColumn<PollDataClass>({
                columnId: "role",
                compare: (a, b) => getPollRole(a.user_is_creator).localeCompare(getPollRole(b.user_is_creator)),
                renderHeaderCell: () => (<b>Role</b>),
                renderCell: (item) => getPollRole(item.user_is_creator),
            })
        ]),
        createTableColumn<PollDataClass>({
            columnId: "poll",
            compare: (a, b) => a.question.localeCompare(b.question),
            renderHeaderCell: () => (<b>Item</b>),
            renderCell: (item: PollDataClass) => (
                <Flex fillFlex gap="small" alignItems="center" wrap>
                    <Flex column gap="smaller" style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
                        {/* Question  */}
                        <Flex wrap gap="smaller" alignItems="center"><Text weight="semibold">{item.question}</Text> {item.poll_is_draft && (<DraftTag />)} {item.poll_results_restricted && (<WarningFreePlanRestriction />)} {item.poll_unlocked_until && (<WarningRestrictionUnlocked appContext={appContext} dateUntil={item.poll_unlocked_until} />)}</Flex>

                        {/* Additional information  */}
                        <Flex gap="smaller" alignItems="center" wrap>
                            {tabContext.tableMode === TabTableMode.modePolls && (<>
                                <Flex gap="smaller" alignItems="center"> <PersonEditRegular /> <Text weight="regular">Creator:</Text> <Text>{item.created_by}</Text></Flex>

                                {item.poll_is_closed ? (<Flex gap="smaller" alignItems="center"> | <LockClosedFilled /> <Text weight="semibold">Closed</Text></Flex>) : (<Flex gap="smaller" alignItems="center"> | <LockOpenRegular /><Text>Open</Text> </Flex>)}

                                {!item.poll_is_draft && (<Flex gap="smaller" alignItems="center"> | <VoteRegular /> <Text weight="regular">Votes:</Text> {item.poll_votes_total}</Flex>)}
                            </>)}

                            {tabContext.tableMode === TabTableMode.modeTemplates && (
                                <Flex gap="smaller" alignItems="center">
                                    <BookmarkRegular />
                                    <Text weight="regular">Template</Text>
                                    | <ShieldPersonRegular /> <Text weight="regular">{item.option_votes_are_anonymous ? "Anonymous voting" : "Non-anonymous voting"}</Text>
                                </Flex>
                            )}
                        </Flex>

                        {(item.option_results_creator_only || item.option_voters_add_answers) && (
                            <Flex gap="smaller" alignItems="center" wrap>
                                <Text weight="semibold">Pro: </Text>
                                {item.option_results_creator_only && (<Flex gap="smaller" alignItems="center"> <DocumentLockRegular /> <InfoLabel label="Creator only" info="The results can only be seen by the poll creator." /></Flex>)}
                                {item.option_results_creator_only && item.option_voters_add_answers && (<Text> | </Text>)}
                                {item.option_voters_add_answers && (<Flex gap="smaller" alignItems="center"> <TextBulletListAddRegular /> <InfoLabel label="Custom answers" info="Voters can add their own answers to the poll." /></Flex>)}
                            </Flex>
                        )}
                    </Flex>

                    {/* Action buttons incl. menu  */}
                    {appContext.environment.current === EnumAppEnvironment.personal_tab && (
                        <>
                            <FlexItem push>
                                {/* View poll results / view template */}
                                <Button appearance="transparent" icon={<EyeRegular />} title={getButtonTitleViewPoll(tabContext.tableMode)} onClick={() => { _viewPoll(item, tabContext.tableMode); }} />
                            </FlexItem>

                            {/* Poll Modus: Copy poll */}
                            {tabContext.tableMode === TabTableMode.modePolls && (
                                <Button appearance="transparent" disabled={!item.user_is_creator} icon={<CopyRegular />} title={(item.user_is_creator ? "Copy poll" : "Copy poll (only for polls that have been created by you)")} onClick={() => { _duplicatePoll(item, false); }} />
                            )}

                            {/* Template Modus: Create poll */}
                            {tabContext.tableMode === TabTableMode.modeTemplates && (
                                <Button appearance="transparent" disabled={!item.user_is_creator} icon={<DocumentOnePageAddRegular />} title="Create poll from template" onClick={() => { _duplicatePoll(item, false); }} />
                            )}


                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Button appearance="transparent" icon={<MoreHorizontalFilled />} title="More" />
                                </MenuTrigger>

                                <MenuPopover>
                                    <MenuList>
                                        {tabContext.tableMode === TabTableMode.modePolls && (
                                            <>
                                                <MenuItem icon={<BookmarkRegular />} disabled={!item.user_is_creator} onClick={async () => { _duplicatePoll(item, true); }}>Make template</MenuItem>
                                                <MenuItem icon={<EditRegular />} disabled={!item.user_is_creator || item.poll_is_closed} onClick={() => { _editPoll(item); }}>Edit poll</MenuItem>
                                                <MenuItem icon={<VoteRegular />} disabled={item.poll_is_closed} onClick={() => { _editVote(item); }}>Change vote</MenuItem>
                                                <MenuItem icon={item.poll_is_closed ? <LockOpenRegular /> : <LockClosedRegular />} disabled={!item.user_is_creator || (item.poll_is_draft && !item.poll_is_closed)} onClick={async () => { _changeOpenClosePoll(item, item.poll_is_closed ? "open" : "close") }}>{item.poll_is_closed ? "Open poll" : "Close poll"}</MenuItem>
                                            </>
                                        )}

                                        {tabContext.tableMode === TabTableMode.modeTemplates && (
                                            <>
                                                <MenuItem icon={<CopyRegular />} disabled={!item.user_is_creator} onClick={async () => { _duplicatePoll(item, true); }}>Copy template</MenuItem>
                                                <MenuItem icon={<EditRegular />} disabled={!item.user_is_creator || item.poll_is_closed} onClick={() => { _editPoll(item); }}>Edit template</MenuItem>
                                            </>
                                        )}

                                        <MenuItem icon={<DeleteRegular />} disabled={!item.user_is_creator} onClick={() => { _deletePoll(item, tabContext.tableMode); }}>{getButtonTitleDeletePoll(tabContext.tableMode)}</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </>
                    )}

                    {appContext.environment.current === EnumAppEnvironment.message_chat && tabContext.tableMode === TabTableMode.modePolls && (
                        <>
                            <FlexItem push>
                                <Button appearance="transparent" icon={<SendRegular />} title="Post existing poll" onClick={() => { _viewPoll(item, tabContext.tableMode); }} />
                            </FlexItem>

                            {
                                // Deactivated 14.11.
                                /*
                                item.user_is_creator && (
                                    <Button appearance="transparent" icon={<CopyRegular />} title="Copy poll" onClick={() => { _duplicatePoll(item, false); }} />
                                )
                                */
                            }
                        </>
                    )}

                    {appContext.environment.current === EnumAppEnvironment.message_chat && tabContext.tableMode === TabTableMode.modeTemplates && (
                        <FlexItem push>
                            <Button appearance="transparent" disabled={!item.user_is_creator} icon={<DocumentOnePageAddRegular />} title="Create poll from template" onClick={() => { _duplicatePoll(item, false); }} />
                        </FlexItem>
                    )}


                </Flex>
            ),
        }),
    ];

    // Define column sizing options for the ListPolls table (data grid).
    const tableColumnSizingOptions: TableColumnSizingOptions = {
        index: { idealWidth: 40 },
        created_on: { idealWidth: 100 },
        role: { idealWidth: 85 },
        poll: { padding: 0 },
    };


    // Get data from backend
    useEffect(() => {
        let active = true;

        const fetchData = async () => {

            const paramEnvironment = (appContext.environment.current === EnumAppEnvironment.personal_tab ? "tab" : "chat");

            const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : "";

            if (!sso_token && appContext.appAuthData.sso_required) {

                // If not token can be retreived and
                // SSO token is required, show empty list.

                tabContext.listPollResults.current = new ListPollResults();
            } else {
                // Default case: Regulary check for poll list.
                // If SSO is enabled, poll list will show all polls.
                // If SSO is not enabled, poll list will show only polls from last 3 weeks.

                if (!tabContext.cacheListPolls.current || !tabContext.cacheListTemplates.current) {
                    // We will load the data for polls and templates in parallel.

                    hyDebugLocal("Caching data first time.");
                    const [dataPolls, dataTemplates] = await Promise.all([
                        backendListPolls(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, paramEnvironment, tabContext.tableSearch.current, TabTableMode.modePolls),
                        backendListPolls(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, paramEnvironment, tabContext.tableSearch.current, TabTableMode.modeTemplates),
                    ]);

                    // Setting the cache data.
                    tabContext.cacheListPolls.current = dataPolls;
                    tabContext.cacheListTemplates.current = dataTemplates;

                    // Setting the current poll results from the cache.
                    // And avoid race conditions.
                    if (active) {
                        if (tabContext.tableMode === TabTableMode.modePolls) {
                            tabContext.listPollResults.current = dataPolls;
                        } else {
                            tabContext.listPollResults.current = dataTemplates;
                        }
                    }
                } else {
                    // We only load the data for the current mode.

                    hyDebugLocal("Updating data (" + tabContext.tableMode.toString() + ")");

                    if (tabContext.tableMode === TabTableMode.modePolls) {
                        const data = await backendListPolls(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, paramEnvironment, tabContext.tableSearch.current, TabTableMode.modePolls);


                        // Avoid race conditions
                        if (active) {
                            tabContext.cacheListPolls.current = data;
                            tabContext.listPollResults.current = data;
                        }

                    } else {
                        const data = await backendListPolls(appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, paramEnvironment, tabContext.tableSearch.current, TabTableMode.modeTemplates);

                        // Avoid race conditions
                        if (active) {
                            tabContext.cacheListTemplates.current = data;
                            tabContext.listPollResults.current = data;
                        }
                    }


                }
            }

            // Update GUI ticker to refresh GUI elements.
            tabContext.setRefreshTickerGUI(refreshTicker);
        };

        fetchData();
        // Only take the latest request (fixing race conditions)
        return () => { active = false; };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabContext.refreshTickerData]);

    return (
        <Segment fillFlex>
            <EmptyFlexRow size="0.25" />
            <Flex column fillFlex gap="small" style={{ maxHeight: (appContext.environment.current === EnumAppEnvironment.personal_tab ? "75vh" : undefined), overflow: "auto" }}>
                <DataGrid
                    id={"ListPollsTable_" + tabContext.refreshTickerGUI /* Use the GUI ticker to force a re-render */}
                    items={tabContext.listPollResults.current.polls}
                    columns={tableColumns}
                    sortable
                    getRowId={(item: PollDataClass) => item.poll_guid}
                    focusMode="composite"
                    resizableColumns
                    resizableColumnsOptions={{ autoFitColumns: true }}
                    columnSizingOptions={tableColumnSizingOptions}
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>

                    <DataGridBody<PollDataClass>>
                        {({ item, rowId }) => (
                            <DataGridRow<PollDataClass> key={rowId} >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>

                <EmptyFlexRow size="1.5" />

                {/* All: Remark: Over 100 items */}
                {tabContext.listPollResults.current.polls.length >= 100 && (
                    <Text><b>Max Limit: </b>The table can show up to 100 {tabContext.tableMode === TabTableMode.modePolls ? "polls" : "templates"}. Please use the search field to narrow down your results.</Text>
                )}

                {/* Polls only: Authenticate */}
                {tabContext.tableMode === TabTableMode.modePolls && !appContext.isSSOEnabled && (
                    <Flex gap="small" alignItems="center" wrap>
                        {appContext.appAuthData.sso_required && (<><WarningFilled color={tokens.colorPaletteYellowForeground3} /><Text><b>Important: </b>Enhanced authentication is required for your organization. Please authenticate to continue accessing your polls.</Text></>)}
                        {!appContext.appAuthData.sso_required && (<Text><b>Remark: </b>The table shows your polls from the last 3 weeks. Please authenticate to access all of your polls.</Text>)}

                        <Button size="small" onClick={async () => { await _authenticate(); }}>Authenticate</Button>
                    </Flex>
                )}

                {/* Templates only */}
                {tabContext.tableMode === TabTableMode.modeTemplates && (
                    <Flex gap="small" alignItems="center" wrap>

                        {/* Authenticate (only when sso is required and not granted) */}
                        {!appContext.isSSOEnabled && appContext.appAuthData.sso_required && (
                            <>
                                <WarningFilled color={tokens.colorPaletteYellowForeground3} />
                                <Text><b>Important: </b>Enhanced authentication is required for your organization. Please authenticate to continue accessing your templates.</Text>
                                <Button size="small" onClick={async () => { await _authenticate(); }}>Authenticate</Button>

                            </>
                        )}

                        {/* 0 Templates (all other cases) */}
                        {!(!appContext.isSSOEnabled && appContext.appAuthData.sso_required) && tabContext.listPollResults.current.polls.length == 0 && (
                            <>
                                <Text><b>That looks empty!</b> Create your first template and speed up your decision making process.</Text>
                                {(appContext.environment.current === EnumAppEnvironment.message_chat) && (
                                    <Button size="small" onClick={async () => { await _create_template_tab(); }}>Open app</Button>
                                )}
                            </>
                        )}


                    </Flex>
                )}

                {/* Templates Only: 0 templates */}
                {tabContext.listPollResults.current.polls.length == 0 && (
                    <Flex gap="small" alignItems="center" wrap>
                        <Text></Text>
                    </Flex>
                )}


            </Flex>
            <EmptyFlexRow size="0.5" />
        </Segment>
    );

    async function _authenticate() {
        // Try to get token with popup
        const token = await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, false);

        if (token) {
            // Reload page
            window.location.reload();
        }
    }

    async function _create_template_tab() {

        // Go to main app
        microsoftTeams.openLink(link_teams_app_template);

    }


    async function _changeOpenClosePoll(item: PollDataClass, action: string) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Call backend to change the poll status
        // We assume that the backend call is successful and update the GUI immediately.
        const sso_token = (appContext.isSSOEnabled) ? await getSSOToken(appContext.isSSOEnabled, appContext.setIsSSOEnabled, true) : null;
        const responseTask = backendEditPollClient(action, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, sso_token, appContext.appAuthData.lang, item.poll_guid, item.poll_secret);

        // GUI fast track: Iterate through tableItems and update the poll that was changed
        tabContext.listPollResults.current.polls = tabContext.listPollResults.current.polls.map((poll) => {
            if (poll.poll_guid === item.poll_guid) {
                poll.poll_is_closed = !poll.poll_is_closed;
            }
            return poll;
        });
        // Update GUI for fast track changes.
        tabContext.setRefreshTickerGUI(refreshTicker);

        // Wait for the final response before refreshing the data from the backend.
        await responseTask;

        // Trigger a refresh of the data from the backend.
        tabContext.setRefreshTickerData(refreshTicker);

    }

    function _deletePoll(item: PollDataClass, mode: TabTableMode) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        const action = (mode === TabTableMode.modePolls ? TabDialogType.DeletePoll : TabDialogType.DeleteTemplate);
        tabContext.setTabDialog(action);
    }

    function _viewPoll(item: PollDataClass, mode: TabTableMode) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        const action = (mode === TabTableMode.modePolls ? TabDialogType.ViewOrPostPoll : TabDialogType.ViewTemplate);
        tabContext.setTabDialog(action);
    }

    function _duplicatePoll(item: PollDataClass, makeTemplate: boolean) {
        if (item.user_is_creator === false) { return; }

        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        const action = (makeTemplate ? TabDialogType.DuplicateTemplate : TabDialogType.DuplicatePoll);
        tabContext.setTabDialog(action);
    }


    function _editVote(item: PollDataClass) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.EditVote);
    }

    function _editPoll(item: PollDataClass) {
        // set active table element
        tabContext.activePollElement.current = item;

        // Open the dialog for confirmation
        tabContext.setTabDialog(TabDialogType.EditPoll);
    }


}

function getPollRole(userIsCreator: boolean): string {
    return userIsCreator ? "Creator" : "Voter";
}

function getButtonTitleViewPoll(mode: TabTableMode): string {
    return (mode === TabTableMode.modePolls ? "View poll results" : "View template");
}

function getButtonTitleDeletePoll(mode: TabTableMode): string {
    return (mode === TabTableMode.modePolls ? "Delete poll" : "Delete template");
}
