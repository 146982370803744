import { Text, Tab, TabList, SelectTabData } from "@fluentui/react-components";
import { useContext, useRef } from "react";
import { TabContext, TabTableMode } from "sub/context/TabContext";
import { refreshTicker } from "sub/library/epCommon";
import { hyDebugLocal } from "sub/library/hyDebug";

export function ListPollsModeSelector() {

    const tabContext = useContext(TabContext);
    let refreshTickerTimeout: NodeJS.Timeout;

    return (
        <TabList size="small" selectedValue={tabContext.tableMode} onTabSelect={(event, data: SelectTabData) => { _switchTableMode(data.value as TabTableMode); }}>
            <Tab value={TabTableMode.modePolls}><Text size={500}>Polls</Text></Tab>
            <Tab value={TabTableMode.modeTemplates}><Text size={500}>Templates</Text></Tab>
        </TabList>
    );

    function _switchTableMode(mode: TabTableMode) {

        if (mode === TabTableMode.modePolls && tabContext.cacheListPolls.current) {
            hyDebugLocal("Cached poll data found: Load cached data into main data.");
            tabContext.listPollResults.current = tabContext.cacheListPolls.current;
        } else if (mode === TabTableMode.modeTemplates && tabContext.cacheListTemplates.current) {
            hyDebugLocal("Cached template data found: Load cached data into main data.");
            tabContext.listPollResults.current = tabContext.cacheListTemplates.current;
        } else {
            hyDebugLocal("No cached data found.");
            tabContext.listPollResults.current.polls = [];
        }
        tabContext.setTableMode(mode);

        // clearTimeout(refreshTickerTimeout);
        // refreshTickerTimeout = setTimeout(() => {
            hyDebugLocal("Refresh data");
            tabContext.setRefreshTickerData(refreshTicker);
        // }, 500);

    }


}

